import { useEffect } from "react";
import "./CreatePdf.css";

const CreatePdf = (props) => {

    const {nom1, nom2, prenom1, prenom2, adresse, somme, annee, typeDePret, modularite, tauxFixeVariable, mensualites, ira, typeDeBanque, autre, autrePrecisez, propositionConseillee, dureeAccord, delegationAssuranceCheckbox, delegationAssurance, dateSignatureMandat, tauxEndettementAvant, tauxEndettementApres, montantCommission} = props;
    const {proposition1, proposition2, proposition3} = props;
    const {handleClickBack} = props;

    const now = new Date();
    const today = now.getDate() + "/" + now.getMonth() + "/" + now.getFullYear();

    useEffect(() => {
      setTimeout(() => {
        window.print();
      }, 1000)
    }, []);

    return (<>
        <div className="container">
          <div className="row justify-content-center text-center d-print-none mt-5">
            <div className="col-8">
              <button className="btn btn-primary btn-lg" onClick={(e) => {handleClickBack()}}>Retour arrière</button>
            </div>
          </div>
      

          {/* Page 1 */}

          <div class="row justify-content-end">
            <div class="col-1"><img className="logo" src="assets/logo.png"/></div>
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-8">
              <h1>CONFIRMATION DE MANDAT</h1>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <table className="table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">EMPRUNTEUR(S)</th>
                    <th className="text-center">COURTIER MANDATE<br/>(ou Mandataire du courtier)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><div>Mr/Mme {nom1} {prenom1}</div><div>Mr/Mme {nom2} {prenom2}</div></td>
                    <td><div>Mr. FRÉDÉRIC BOUHAMI</div><div>SAS AFP COURTAGE</div></td>
                  </tr>
                  <tr>
                    <td>Deumerant : {adresse}</td>
                    <td>7 rue de la mairie 69210 LENTILLY</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div>
                      Le(s) emprunteur(s) confirme(nt) sa (leur) volonté d’obtenir le(s) concours financier(s) par l’intermédiaire du courtier, d’un montant total de : {somme}€  sur {annee} années.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row justify-content-center footer">
            <div className="col-10">
            AFP COURTAGE – 7 Rue de la Mairie 69210 LENTILLY SAS au capital de 1000 euros immatriculé au RCS Lyon sous le numéro 823 955 471 Courtier en opérations de banque et en services de paiement ORIAS : 16006977 (www.orias.fr) – RCP Allianz IARD : 58828019 www.groupe-afp.fr
            </div>
          </div>

        </div>

        <div className="container">
          {/* Page 2  */}

          <div class="row justify-content-end">
            <div class="col-1"><img className="logo" src="assets/logo.png"/></div>
          </div>

          <div className="border p-2">
            <div className="row">
              <div>
                <b>Le courtier mandate ou mandataire du courtier</b> déclare avoir analysé les différents contrats disponibles sur le marché afin de proposer à l’(aux) emprunteur(s) un contrat adapté à ses(leurs) besoins. Un tableau présentant les différentes offres identifiéesainsi que leurs caractéristiques essentielles se trouve donc en pièce jointe. Compte-tenu des attentes prioritaires exprimées par le(s) emprunteur(s) ci-dessous : 
              </div>
              <div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="typeDePret" checked={typeDePret} />
                  <label class="form-check-label" for="typeDePret">Type de prêt</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="modularite" checked={modularite} />
                  <label class="form-check-label" for="modularite">Modularité</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="tauxFixeVariable" checked={tauxFixeVariable} />
                  <label class="form-check-label" for="tauxFixeVariable">Taux (fixe ou variable)</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="mensualites" checked={mensualites} />
                  <label class="form-check-label" for="mensualites">Mensualités</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="ira" checked={ira} />
                  <label class="form-check-label" for="ira">IRA</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="typeDeBanque" checked={typeDeBanque} />
                  <label class="form-check-label" for="typeDeBanque">Type de banque</label>
                </div>
              </div>
              <div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="autre" checked={autre} />
                  <label class="form-check-label" for="autre">Autres (Précisez) : {autrePrecisez} </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div>
                  Nous conseillons à l’(aux) emprunteur(s) la proposition n°{propositionConseillee}. sur laquelle nous avons obtenu un accord de principe sous réserve de pouvoir remplir les conditions suivantes :
                </div>
                <ul>
                  <li>accord garantie</li>
                  <li>accord assurances</li>
                </ul>
                <div>Cet accord a été émis pour une durée de {dureeAccord}</div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="delegationAssurance" checked={delegationAssuranceCheckbox} />
                  <label class="form-check-label" for="delegationAssurance">Mise en place d’une délégation d’assurance : {delegationAssurance} </label>
                </div>
                <div>(sous réserve d’acceptation définitive).La FSI est remise à l’emprunteur</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div>
                  Les emprunteurs ont la possibilité de changer d’assurance emprunteur (ADE) à chaque date anniversaire de signature de l’offre de prêt.
                </div>
                <div>
                  Cette proposition correspond au projet de financement de(s) l’emprunteur(s) tel qu’il(s) nous l’a(ont) exposé dans le document de recherche de mandat qu’il(s) a(ont) signé le {dateSignatureMandat}
                </div>
                <div>
                  Le taux d’endettement avant : {tauxEndettementAvant}
                </div>
                <div>
                  Le taux d’endettement après: {tauxEndettementApres}
                </div>
                <div>
                  En cas de remboursement anticipé du ou des crédit(s) sollicité(s), le(s) emprunteur(s) pourra(ont) être redevable(s) à l’égard de la banque, d’une indemnité décrite dans l’offre de prêt(s). (cf « IRA » sur le lexique du crédit)
                </div>
                <div>
                  <b>Le courtier mandate ou mandataire du courtier</b> percevra une commission de l'établissement bancaire correspondant à 1% maximum de la somme financée et pouvant être soumise à un plafond, soit un montant de {montantCommission}€
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div><b>Le courtier mandate ou mandataire du courtier</b></div>
                <div>Frédéric Bouhami</div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div><b>Le :</b></div>
              </div>
              <div className="col-6">
                <div><b>Signature :</b></div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div>
                  <b>Le(s) emprunteur(s)</b> reconnaît(ssent) :
                </div>
                <div>
                  <ul>
                    <li>que plusieurs simulations de financement lui (leur) ont été proposées</li>
                    <li>avoir disposé de toutes les explications adéquatesà la compréhension et la comparaison des différents types de contrats présentés dans le tableau ci-joint pour fonder un choix éclairé</li>
                    <li>connaître et accepter les caractéristiques duprêt dont l’avis favorable à été obtenu et que, par conséquent, la proposition préconisée par le Courtier Mandaté (ou mandataire du courtier) correspond à ses(leurs) besoins</li>
                    <li> que toutes les informations transmises dans le présent document, y compris les charges financières, sont susceptibles d’évoluer et que les éléments définitifs seront communiqués par la banque dans l’offre de prêt</li>
                    <li>avoir été informé(s) que les impayés afférents au(x) crédit(s) sollicité(s) peuvent avoir de graves conséquences et qu’il</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>


          <div class="row justify-content-center footer">
            <div className="col-10">
            AFP COURTAGE – 7 Rue de la Mairie 69210 LENTILLY SAS au capital de 1000 euros immatriculé au RCS Lyon sous le numéro 823 955 471 Courtier en opérations de banque et en services de paiement ORIAS : 16006977 (www.orias.fr) – RCP Allianz IARD : 58828019 www.groupe-afp.fr
            </div>
          </div>


        </div>

        <div className="container">
          {/* Page 3  */}

          <div class="row justify-content-end">
            <div class="col-1"><img className="logo" src="assets/logo.png"/></div>
          </div>

          <div className="row border p-1">
            <div className="text-center">
              <b>Nous vous rappelons que la souscription d’un crédit peut avoir des conséquences sur les biens remis en garantie en cas de défaillance de l’emprunteur.</b>
            </div>
            <div>
              Conformément aux articles L 519-6 du Code monétaire et financier,« Il est interdit à toute personne physique ou morale qui apporte son concours, à quelque titre que ce soit et de quelque manière que ce soit, directement ou indirectement, à l'obtention ou à l'octroi d'un prêt d'argent, de percevoir une somme représentative de provision, de commissions, de frais de recherche, de démarches, de constitution de dossier ou d'entremise quelconque, avant le versement effectif des fonds prêtés.Il lui est également interdit, avant la remise des fonds et de la copie de l'acte, de présenter à l'acceptation de l'emprunteur des lettres de change, ou de lui faire souscrire des billets à ordre, en recouvrement des frais d'entremise ou des commissions mentionnés à l'alinéa précédent… »
            </div>
            <div className="mt-3">
              En matière de crédit immobilier, le(s) emprunteur(s) dispose(nt) d’un délai de réflexion de 10 jours après réception de l’offre de prêt pour donner son (leur) accord. L’achat est subordonné à l’obtention du prêt, s’il n’est pas obtenu, le vendeur doit lui rembourser les sommes versées.
            </div>
          </div>
          
          
          <div className="row mt-5 border">
            <div className="col">
              <div className="mb-3">L'emprunter</div>
              <div className="mb-3">M./Mme/Melle : {nom1} {prenom1}</div>
              <div className="mb-3">Le : {today}</div>
              <div className="mb-5">Mention manuscrite « BON POUR ACCORD »</div>
              <div className="mt-5 mb-5">Signature : </div>
            </div>
            <div className="col">
              <div className="mb-3">Le co-emprunter</div>
              <div className="mb-3">M./Mme/Melle : {nom2} {prenom2}</div>
              <div className="mb-3">Le : {today}</div>
              <div className="mb-5">Mention manuscrite « BON POUR ACCORD »</div>
              <div className="mt-5 mb-5">Signature : </div>
            </div>
          </div>


          <div class="row justify-content-center footer">
            <div className="col-10">
            AFP COURTAGE – 7 Rue de la Mairie 69210 LENTILLY SAS au capital de 1000 euros immatriculé au RCS Lyon sous le numéro 823 955 471 Courtier en opérations de banque et en services de paiement ORIAS : 16006977 (www.orias.fr) – RCP Allianz IARD : 58828019 www.groupe-afp.fr
            </div>
          </div>


        </div>

        <div className="container">
          {/* Page 4  */}

          <div class="row justify-content-end">
            <div class="col-1"><img className="logo" src="assets/logo.png"/></div>
          </div>

          <div className="row justify-content-center text-center">
            <div className="col-8">
              <h1>ANNEXE :</h1>
              <div>Tableau récapitulatif des simulations</div>
            </div>
          </div>

          <div className="row">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td> &nbsp; </td>
                  <td>Montant emprunté</td>
                  <td>Durée de l’emprunt</td>
                  <td>Type de taux</td>
                  <td>Type de prêt</td>
                  <td>Montant des mensualités hors assurances</td>
                  <td>Mode de garantie du prêt et coût</td>
                  <td>IRA</td>
                  <td>Frais de dossier</td>
                  <td>Apport personnel</td>
                  <td>Honoraires</td>
                  <td>Coût des intérêts</td>
                  <td>Coût du crédit hors assurance</td>
                </tr>
                <tr>
                  <td>Proposition  n°1 {proposition1.nom}</td>
                  <td>{proposition1.montant}€</td>
                  <td>{proposition1.duree} ANS</td>
                  <td>TAUX FIXE {proposition1.taux}%</td>
                  <td>{proposition1.type}</td>
                  <td>{proposition1.montantMensualites}</td>
                  <td>{proposition1.modeGarantie}<br/>{proposition1.coutGarantie}</td>
                  <td>{proposition1.ira}</td>
                  <td>{proposition1.fraisDossier}</td>
                  <td>{proposition1.apport}</td>
                  <td>{proposition1.honoraires}</td>
                  <td>{proposition1.coutInterets}</td>
                  <td>{proposition1.coutCredit}</td>
                </tr>
                <tr>
                  <td>Proposition  n°2 {proposition2.nom}</td>
                  <td>{proposition2.montant}€</td>
                  <td>{proposition2.duree} ANS</td>
                  <td>TAUX FIXE {proposition2.taux}%</td>
                  <td>{proposition2.type}</td>
                  <td>{proposition2.montantMensualites}</td>
                  <td>{proposition2.modeGarantie}<br/>{proposition2.coutGarantie}</td>
                  <td>{proposition2.ira}</td>
                  <td>{proposition2.fraisDossier}</td>
                  <td>{proposition2.apport}</td>
                  <td>{proposition2.honoraires}</td>
                  <td>{proposition2.coutInterets}</td>
                  <td>{proposition2.coutCredit}</td>
                </tr>
                <tr>
                  <td>Proposition  n°3 {proposition3.nom}</td>
                  <td>{proposition3.montant}€</td>
                  <td>{proposition3.duree} ANS</td>
                  <td>TAUX FIXE {proposition3.taux}%</td>
                  <td>{proposition3.type}</td>
                  <td>{proposition3.montantMensualites}</td>
                  <td>{proposition3.modeGarantie}<br/>{proposition3.coutGarantie}</td>
                  <td>{proposition3.ira}</td>
                  <td>{proposition3.fraisDossier}</td>
                  <td>{proposition2.apport}</td>
                  <td>{proposition2.honoraires}</td>
                  <td>{proposition3.coutInterets}</td>
                  <td>{proposition3.coutCredit}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row mt-3">
            <div><i>** Caution, Hypothèque, Privilège de Prêteur de denier, nantissement ....</i><b>En cas de défaillance de paiement des échéances du prêt immobilier, cette garantie protège la banque. La mise en œuvre de cette garantie peut aller jusqu’à la vente forcée du bien remis en garantie.</b> (cf lexique)</div>
          </div>


          <div class="row justify-content-center footer">
            <div className="col-10">
            AFP COURTAGE – 7 Rue de la Mairie 69210 LENTILLY SAS au capital de 1000 euros immatriculé au RCS Lyon sous le numéro 823 955 471 Courtier en opérations de banque et en services de paiement ORIAS : 16006977 (www.orias.fr) – RCP Allianz IARD : 58828019 www.groupe-afp.fr
            </div>
          </div>

          <div className="row justify-content-center text-center d-print-none mt-5 mb-5">
            <div className="col-8">
              <button className="btn btn-primary btn-lg" onClick={(e) => {handleClickBack()}}>Retour arrière</button>
            </div>
          </div>
        </div>
    </>);

}

export default CreatePdf;