/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {useState, useEffect} from "react";
import CreatePdf from "./CreatePdf";


function App() {

  const [showPdf, setShowPdf] = useState(false)
  const [nom1, setNom1] = useState("")
  const [nom2, setNom2] = useState("")
  const [prenom1, setPrenom1] = useState("")
  const [prenom2, setPrenom2] = useState("")
  const [adresse, setAdresse] = useState("")
  const [somme, setSomme] = useState(0)
  const [annee, setAnnee] = useState(0)
  const [typeDePret, setTypeDePret] = useState(false)
  const [modularite, setModularite] = useState(false)
  const [tauxFixeVariable, setTauxFixeVariable] = useState(false)
  const [mensualites, setMensualites] = useState(false)
  const [ira, setIra] = useState(false)
  const [typeDeBanque, setTypeDeBanque] = useState(false)
  const [autre, setAutre] = useState(false)
  const [autrePrecisez, setAutrePrecisez] = useState(false)
  const [propositionConseillee, setPropositionConseillee] = useState(0)
  const [dureeAccord, setDureeAccord] = useState(0)
  const [delegationAssuranceCheckbox, setDelegationAssuranceCheckbox] = useState(false)
  const [delegationAssurance, setDelegationAssurance] = useState("")
  const [dateSignatureMandat, setDateSignatureMandat] = useState("")
  const [tauxEndettementAvant, setTauxEndettementAvant] = useState("")
  const [tauxEndettementApres, setTauxEndettementApres] = useState("")
  const [montantCommission, setMontantCommission] = useState("")
  const p = {
    nom: "",
    montant: 0,
    duree: 0,
    taux: 0,
    type: "",
    montantMensualites:0,
    modeGarantie:"",
    coutGarantie:0,
    ira: "",
    fraisDossier: 0,
    coutInterets: 0,
    coutCredit: 0,
    apport: 0,
    honoraires: 0,
  }
  const [proposition1, setProposition1] = useState(p)
  const [proposition2, setProposition2] = useState(p)
  const [proposition3, setProposition3] = useState(p)

  const setProposition1Value = (key, value) => {
    let np = {...proposition1}
    np[key] = value;
    setProposition1(np)
  }
  const setProposition2Value = (key, value) => {
    let np = {...proposition2}
    np[key] = value;
    setProposition2(np)
  }
  const setProposition3Value = (key, value) => {
    let np = {...proposition3}
    np[key] = value;
    setProposition3(np)
  }

  useEffect(() => {
    var borrowed_capital = parseFloat(proposition1.montant);
    var loan_duration = parseFloat(proposition1.duree);
    var interest_rate_percent = parseFloat(proposition1.taux);
    var interest_rate = (interest_rate_percent / 100);
    var monthly_payments = compute_monthly_payments(
      borrowed_capital,
      loan_duration,
      interest_rate
    );
    var loan_cost = compute_loan_cost(borrowed_capital, loan_duration, monthly_payments);

    let np = {...proposition1}
    np.montantMensualites = parseFloat(monthly_payments.toFixed(2));
    np.coutInterets = parseFloat(loan_cost.toFixed(2));
    setProposition1(np);

  }, [proposition1.montant, proposition1.duree, proposition1.taux]);

  useEffect(() => {
    const coutCredit = parseFloat(proposition1.fraisDossier) + parseFloat(proposition1.coutGarantie) + parseFloat(proposition1.coutInterets);
    setProposition1Value("coutCredit", coutCredit);

  }, [proposition1.fraisDossier, proposition1.coutGarantie, proposition1.coutInterets]);

  useEffect(() => {
    var borrowed_capital = parseFloat(proposition2.montant);
    var loan_duration = parseFloat(proposition2.duree);
    var interest_rate_percent = parseFloat(proposition2.taux);
    var interest_rate = (interest_rate_percent / 100);
    var monthly_payments = compute_monthly_payments(
      borrowed_capital,
      loan_duration,
      interest_rate
    );
    var loan_cost = compute_loan_cost(borrowed_capital, loan_duration, monthly_payments);

    let np = {...proposition2}
    np.montantMensualites = parseFloat(monthly_payments.toFixed(2));
    np.coutInterets = parseFloat(loan_cost.toFixed(2));
    setProposition2(np);

  }, [proposition2.montant, proposition2.duree, proposition2.taux]);

  useEffect(() => {
    const coutCredit = parseFloat(proposition2.fraisDossier) + parseFloat(proposition2.coutGarantie) + parseFloat(proposition2.coutInterets);
    setProposition2Value("coutCredit", coutCredit);

  }, [proposition2.fraisDossier, proposition2.coutGarantie, proposition2.coutInterets]);

  useEffect(() => {
    var borrowed_capital = parseFloat(proposition3.montant);
    var loan_duration = parseFloat(proposition3.duree);
    var interest_rate_percent = parseFloat(proposition3.taux);
    var interest_rate = (interest_rate_percent / 100);
    var monthly_payments = compute_monthly_payments(
      borrowed_capital,
      loan_duration,
      interest_rate
    );
    var loan_cost = compute_loan_cost(borrowed_capital, loan_duration, monthly_payments);

    let np = {...proposition3}
    np.montantMensualites = parseFloat(monthly_payments.toFixed(2));
    np.coutInterets = parseFloat(loan_cost.toFixed(2));
    setProposition3(np);

  }, [proposition3.montant, proposition3.duree, proposition3.taux]);

  useEffect(() => {
    const coutCredit = parseFloat(proposition3.fraisDossier) + parseFloat(proposition3.coutGarantie) + parseFloat(proposition3.coutInterets);
    setProposition3Value("coutCredit", coutCredit);

  }, [proposition3.fraisDossier, proposition3.coutGarantie, proposition3.coutInterets]);

  
  function compute_monthly_payments(borrowed_capital, loan_duration, interest_rate) {
    var monthly_payments =
    (borrowed_capital * interest_rate / 12)
    /
    (1 - (1 / (Math.pow((1 + (interest_rate / 12)), loan_duration * 12))));
    var monthly_payments_rounded = parseFloat(monthly_payments.toFixed(2));
    return monthly_payments_rounded;
  }
  
  
  function compute_loan_cost(borrowed_capital, loan_duration, monthly_payments) {
    var loan_cost = (loan_duration * monthly_payments * 12) - borrowed_capital;
    var loan_cost_rounded = parseInt(loan_cost.toFixed(0), 10);
    return loan_cost_rounded;
  }

  const handleClickCreatePdf = (e) => {
    setShowPdf(true)
  }

  const handleClickBack = (e) => {
    setShowPdf(false)
  }
  
  return !showPdf ? (
    <div className="container mt-5 mb-5">
      <div className="col">
        <form>
          <div className="mb-3">
            <label class="form-label">Emprunteur</label>
            <div class="input-group mb-3">
              <span class="input-group-text">Nom et prénom</span>
              <input type="text" class="form-control" placeholder="Nom" onChange={(e) => setNom1(e.target.value)} />
              <input type="text" class="form-control" placeholder="Prénom" onChange={(e) => setPrenom1(e.target.value)}/>
            </div>
          </div>
          <div className="mb-3">
            <label class="form-label">Co-emprunteur (facultatif)</label>
            <div class="input-group mb-3">
              <span class="input-group-text">Nom et prénom</span>
              <input type="text" class="form-control" placeholder="Nom" onChange={(e) => setNom2(e.target.value)} />
              <input type="text" class="form-control" placeholder="Prénom" onChange={(e) => setPrenom2(e.target.value)}/>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Adresse</label>
            <input type="email" class="form-control" id="adresse" onChange={(e) => setAdresse(e.target.value)}  />
          </div>
          <div class="row mb-3">
            <div class="col-4">
              <label class="form-label">Montant de l'emprunt</label>
              <div class="input-group mb-3">
                <input type="number" step="1" class="form-control" placeholder="Montant" onChange={(e) => setSomme(e.target.value)} />
                <span class="input-group-text">€</span>
              </div>
            </div>
            <div class="col-3">
              <label class="form-label">Durée de l'emprunt</label>
              <div class="input-group mb-3">
                <input type="number" step="1" class="form-control" placeholder="Durée" onChange={(e) => setAnnee(e.target.value)} />
                <span class="input-group-text">année</span>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="typeDePret" onChange={(e) => setTypeDePret(e.target.value)} />
              <label class="form-check-label" for="typeDePret">Type de prêt</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="modularite" onChange={(e) => setModularite(e.target.value)} />
              <label class="form-check-label" for="modularite">Modularité</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="tauxFixeVariable" onChange={(e) => setTauxFixeVariable(e.target.value)} />
              <label class="form-check-label" for="tauxFixeVariable">Taux (fixe ou variable)</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="mensualites" onChange={(e) => setMensualites(e.target.value)} />
              <label class="form-check-label" for="mensualites">Mensualités</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="ira" onChange={(e) => setIra(e.target.value)} />
              <label class="form-check-label" for="ira">IRA</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="typeDeBanque" onChange={(e) => setTypeDeBanque(e.target.value)} />
              <label class="form-check-label" for="typeDeBanque">Type de banque</label>
            </div>
          </div>
          <div className="mb-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="autre" onChange={(e) => setAutre(e.target.value)} />
              <label class="form-check-label" for="autre">Autres (Précisez) : <input type="text" class="form-control" style={{display: "inline", width: "400px"}} onChange={(e) => setAutrePrecisez(e.target.value)}/></label>
            </div>
          </div>
          <div className="row">
          <div class="col-3 mb-3">
            <label class="form-label">Proposition conseillée</label>
            <input type="number" step="1" min="1" max="3" class="form-control" onChange={(e) => setPropositionConseillee(e.target.value)} style={{width:"100px"}}  />
          </div>
          <div class="col-3 mb-3">
            <label class="form-label">Durée de l'accord</label>
            <input type="text" class="form-control" onChange={(e) => setDureeAccord(e.target.value)} style={{width:"200px"}}  />
          </div>

          </div>
          <div className="mb-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="delegationAssuranceCheckbox" onChange={(e) => setDelegationAssuranceCheckbox(e.target.value)} />
              <label class="form-check-label" for="delegationAssuranceCheckbox">Mise en place d’une délégation d’assurance : <input type="text" class="form-control" style={{display: "inline", width: "400px"}} onChange={(e) => setDelegationAssurance(e.target.value)}/></label>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Mandat signé le</label>
            <input type="date" class="form-control" onChange={(e) => setDateSignatureMandat(e.target.value)} style={{width:"200px"}}  />
          </div>
          <div className="mb-3">
            <label class="form-label">Taux d'endettement</label>
            <div class="input-group mb-3" style={{width:"500px"}}>
              <span class="input-group-text">Avant / après</span>
              <input type="number" step="0.01" class="form-control" placeholder="Avant" onChange={(e) => setTauxEndettementAvant(e.target.value)} />
              <input type="number" step="0.01" class="form-control" placeholder="Après" onChange={(e) => setTauxEndettementApres(e.target.value)}/>
            </div>
          </div>
          <div class="mb-3">
            <label className="form-label">Montant de la commission</label>
            <div class="input-group" style={{width:"200px"}}>
              <input type="number" step="1" class="form-control" />
              <span class="input-group-text">€</span>
            </div>
          </div>
          <div className="mb-3 border-bottom pb-3">
            <h5>Proposition 1</h5>
            <div className="row mb-4">
              <div className="col-3  mb-3">
                <label className="form-label">Nom de la proposition</label>
                <input type="text" class="form-control" value={proposition1.nom} onChange={(e) => setProposition1Value("nom", e.target.value)}  />
              </div>
              <div className="col-2 mb-3">
                <label className="form-label">Montant</label>
                <div class="input-group mb-3">
                  <input type="number" step="1" class="form-control" value={proposition1.montant} onChange={(e) => setProposition1Value("montant", e.target.value)} />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Durée</label>
                <div class="input-group mb-3">
                  <input type="number" step="1" class="form-control" value={proposition1.duree} onChange={(e) => setProposition1Value("duree", e.target.value)}  />
                  <span class="input-group-text">année</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Taux fixe</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition1.taux} onChange={(e) => setProposition1Value("taux", e.target.value)} />
                  <span class="input-group-text">%</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Type de prêt</label>
                <input type="text" class="form-control" value={proposition1.type} onChange={(e) => setProposition1Value("type", e.target.value)}  />
              </div>
              <div className="col-2  mb-3">
                <label className="form-label" style={{marginTop:"-24px"}}>Montant des mensualités hors assurances</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition1.montantMensualites} onChange={(e) => setProposition1Value("montantMensualites", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-3  mb-3">
                <label className="form-label">Mode de garantie du prêt</label>
                <select class="form-select" onChange={(e) => setProposition1Value("modeGarantie", e.target.value)}>
                  <option selected={proposition1.modeGarantie === "Caution SACCEF non restituable" ? "true" : "false"}>Caution SACCEF non restituable</option>
                  <option selected={proposition1.modeGarantie === "Caution crédit logement partiellement restituable" ? "true" : "false"}>Caution crédit logement partiellement restituable</option>
                  <option selected={proposition1.modeGarantie === "Hypothèque" ? "true" : "false"}>Hypothèque</option>
                </select>
                {/* <input type="text" class="form-control" value={proposition1.modeGarantie} onChange={(e) => setProposition1Value("modeGarantie", e.target.value)}  /> */}
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Coût de la garantie du prêt </label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition1.coutGarantie} onChange={(e) => setProposition1Value("coutGarantie", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">IRA</label>
                <input type="text" class="form-control" value={proposition1.ira} onChange={(e) => setProposition1Value("ira", e.target.value)}  />
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Frais de dossier</label>
                <div class="input-group mb-3">
                  <input type="number" step="1" class="form-control" value={proposition1.fraisDossier} onChange={(e) => setProposition1Value("fraisDossier", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Honoraires</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition1.honoraires} onChange={(e) => setProposition1Value("honoraires", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Apport personnel</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition1.apport} onChange={(e) => setProposition1Value("apport", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Coût des intérêts</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition1.coutInterets} onChange={(e) => setProposition1Value("coutInterets", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label" style={{marginTop:"-24px"}}>Coût du crédit hors assurance</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition1.coutCredit} onChange={(e) => setProposition1Value("coutCredit", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3 border-bottom pb-3">
            <h5>Proposition 2</h5>
            <div className="row mb-4">
              <div className="col-3  mb-3">
                <label className="form-label">Nom de la proposition</label>
                <input type="text" class="form-control" value={proposition2.nom} onChange={(e) => setProposition2Value("nom", e.target.value)}  />
              </div>
              <div className="col-2 mb-3">
                <label className="form-label">Montant</label>
                <div class="input-group mb-3">
                  <input type="number" step="1" class="form-control" value={proposition2.montant} onChange={(e) => setProposition2Value("montant", e.target.value)} />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Durée</label>
                <div class="input-group mb-3">
                  <input type="number" step="1" class="form-control" value={proposition2.duree} onChange={(e) => setProposition2Value("duree", e.target.value)}  />
                  <span class="input-group-text">année</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Taux fixe</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition2.taux} onChange={(e) => setProposition2Value("taux", e.target.value)} />
                  <span class="input-group-text">%</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Type de prêt</label>
                <input type="text" class="form-control" value={proposition2.type} onChange={(e) => setProposition2Value("type", e.target.value)}  />
              </div>
              <div className="col-2  mb-3">
                <label className="form-label" style={{marginTop:"-24px"}}>Montant des mensualités hors assurances</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition2.montantMensualites} onChange={(e) => setProposition2Value("montantMensualites", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-3  mb-3">
                <label className="form-label">Mode de garantie du prêt</label>
                <select class="form-select" onChange={(e) => setProposition2Value("modeGarantie", e.target.value)}>
                  <option selected={proposition2.modeGarantie === "Caution SACCEF non restituable" ? "true" : "false"}>Caution SACCEF non restituable</option>
                  <option selected={proposition2.modeGarantie === "Caution crédit logement partiellement restituable" ? "true" : "false"}>Caution crédit logement partiellement restituable</option>
                  <option selected={proposition2.modeGarantie === "Hypothèque" ? "true" : "false"}>Hypothèque</option>
                </select>
                {/* <input type="text" class="form-control" value={proposition2.modeGarantie} onChange={(e) => setProposition2Value("modeGarantie", e.target.value)}  /> */}
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Coût de la garantie du prêt </label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition2.coutGarantie} onChange={(e) => setProposition2Value("coutGarantie", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">IRA</label>
                <input type="text" class="form-control" value={proposition2.ira} onChange={(e) => setProposition2Value("ira", e.target.value)}  />
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Frais de dossier</label>
                <div class="input-group mb-3">
                  <input type="number" step="1" class="form-control" value={proposition2.fraisDossier} onChange={(e) => setProposition2Value("fraisDossier", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Honoraires</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition2.honoraires} onChange={(e) => setProposition2Value("honoraires", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Apport personnel</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition2.apport} onChange={(e) => setProposition2Value("apport", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Coût des intérêts</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition2.coutInterets} onChange={(e) => setProposition2Value("coutInterets", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label" style={{marginTop:"-24px"}}>Coût du crédit hors assurance</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition2.coutCredit} onChange={(e) => setProposition2Value("coutCredit", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-3">
            <h5>Proposition 3</h5>
            <div className="row mb-4">
              <div className="col-3  mb-3">
                <label className="form-label">Nom de la proposition</label>
                <input type="text" class="form-control" value={proposition3.nom} onChange={(e) => setProposition3Value("nom", e.target.value)}  />
              </div>
              <div className="col-2 mb-3">
                <label className="form-label">Montant</label>
                <div class="input-group mb-3">
                  <input type="number" step="1" class="form-control" value={proposition3.montant} onChange={(e) => setProposition3Value("montant", e.target.value)} />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Durée</label>
                <div class="input-group mb-3">
                  <input type="number" step="1" class="form-control" value={proposition3.duree} onChange={(e) => setProposition3Value("duree", e.target.value)}  />
                  <span class="input-group-text">année</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Taux fixe</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition3.taux} onChange={(e) => setProposition3Value("taux", e.target.value)} />
                  <span class="input-group-text">%</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Type de prêt</label>
                <input type="text" class="form-control" value={proposition3.type} onChange={(e) => setProposition3Value("type", e.target.value)}  />
              </div>
              <div className="col-2  mb-3">
                <label className="form-label" style={{marginTop:"-24px"}}>Montant des mensualités hors assurances</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition3.montantMensualites} onChange={(e) => setProposition3Value("montantMensualites", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-3  mb-3">
                <label className="form-label">Mode de garantie du prêt</label>
                <select class="form-select" onChange={(e) => setProposition3Value("modeGarantie", e.target.value)}>
                  <option selected={proposition3.modeGarantie === "Caution SACCEF non restituable" ? "true" : "false"}>Caution SACCEF non restituable</option>
                  <option selected={proposition3.modeGarantie === "Caution crédit logement partiellement restituable" ? "true" : "false"}>Caution crédit logement partiellement restituable</option>
                  <option selected={proposition3.modeGarantie === "Hypothèque" ? "true" : "false"}>Hypothèque</option>
                </select>
                {/* <input type="text" class="form-control" value={proposition3.modeGarantie} onChange={(e) => setProposition3Value("modeGarantie", e.target.value)}  /> */}
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Coût de la garantie du prêt </label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition3.coutGarantie} onChange={(e) => setProposition3Value("coutGarantie", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">IRA</label>
                <input type="text" class="form-control" value={proposition3.ira} onChange={(e) => setProposition3Value("ira", e.target.value)}  />
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Frais de dossier</label>
                <div class="input-group mb-3">
                  <input type="number" step="1" class="form-control" value={proposition3.fraisDossier} onChange={(e) => setProposition3Value("fraisDossier", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Honoraires</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition3.honoraires} onChange={(e) => setProposition3Value("honoraires", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Apport personnel</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition3.apport} onChange={(e) => setProposition3Value("apport", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label">Coût des intérêts</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition3.coutInterets} onChange={(e) => setProposition3Value("coutInterets", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
              <div className="col-2  mb-3">
                <label className="form-label" style={{marginTop:"-24px"}}>Coût du crédit hors assurance</label>
                <div class="input-group mb-3">
                  <input type="number" step="0.01" class="form-control" value={proposition3.coutCredit} onChange={(e) => setProposition3Value("coutCredit", e.target.value)}  />
                  <span class="input-group-text">€</span>
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-primary btn-lg" onClick={handleClickCreatePdf}>Créer le PDF</button>
          
        </form>
      </div>
    </div>
  ) : (
      <CreatePdf
      handleClickBack={handleClickBack}
      nom1={nom1}
      nom2={nom2}
      prenom1={prenom1}
      prenom2={prenom2}
      adresse={adresse}
      somme={somme}
      annee={annee}
      typeDePret={typeDePret}
      modularite={modularite}
      tauxFixeVariable={tauxFixeVariable}
      mensualites={mensualites}
      ira={ira}
      typeDeBanque={typeDeBanque}
      autre={autre}
      autrePrecisez={autrePrecisez}
      propositionConseillee={propositionConseillee}
      dureeAccord={dureeAccord}
      delegationAssuranceCheckbox={delegationAssuranceCheckbox}
      delegationAssurance={delegationAssurance}
      dateSignatureMandat={dateSignatureMandat}
      tauxEndettementAvant={tauxEndettementAvant}
      tauxEndettementApres={tauxEndettementApres}
      montantCommission={montantCommission}
      proposition1={proposition1}
      proposition2={proposition2}
      proposition3={proposition3}
      />
  );
}

export default App;
